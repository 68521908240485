<template>
  <div class="container">
    <div class="pageDrawer pageOpen">
      <div id="pageHeader">
        <div class="container max-800">
          <div id="infoHolder">
            <i class="tio-help-outlined"> </i>
            About {{ config.blockchain_name }}
          </div>
        </div>
        <div id="closeHolder">
          <img
            src="@/assets/img/close.svg"
            id="closePage"
            @click="closePage()"
          />
        </div>
        <div class="clearfix"></div>
      </div>
      <div class="container text-start mt-5 pt-3 max-800">
        <article>
          <h1>About {{ config.blockchain_name }}</h1>
          <ul class="no-bullets">
            <li>
              <a :href="config.blockchain_url" target="_blank"
                >{{ config.token_name }}({{ config.currency_code }})</a
              >
              is the underlying token for the
              {{ config.blockchain_name }} Blockchain.
            </li>
            <li>
              {{ config.blockchain_name }} is a fast, high-performance
              Ethereum-compatible public blockchain, meaning it uses the same
              language and toolset, with fully decentralized trust that brings
              scale to decentralized applications.
            </li>
            <li>
              It promises higher throughput, faster confirmation times, and
              lower costs. It is essentially a cheaper and faster Ethereum.
            </li>
            <li>
              {{ config.token_name }}({{ config.currency_code }}), can freely
              buy / sell on a variety of
              <a :href="config.exchange_url" target="_blank">exchanges</a>
            </li>
          </ul>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    document.body.style.overflow = "hidden";
    return {
      history: window.history,
      config: this.$store.getters.getConfig,
    };
  },
  methods: {
    closePage() {
      this.history.back();
      document.body.style.removeProperty("overflow");
    },
  },
  watch: {
    $route() {
      this.config = this.$store.getters.getConfig;
    },
  },
};
</script>
<style scoped>
.aboutList {
  margin: 0px;
  padding: 0px;
  text-align: left;
  list-style-type: none;
  padding-top: 10px;
}
.aboutList li {
  padding: 15px;
  line-height: 25px;
}
a {
  color: #5dbaee;
  font-weight: bold;
}
</style>
